import React, {Component} from "react";
import {REPOS} from "../data";
import Button from "../lib/Button";

class List extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className="repo-list">
        {REPOS.map((a, i) =>
          <div key={a.title}
               onMouseOver={() => this.props.onMouseOver(i)}
               className="repo flex-center">
            <div className="title">
              {a.title}
              <span className="type">{a.type}</span>
            </div>
            <div className="detail flex-center">
              <div className="description">{a.description}</div>
            </div>
            <div className="btns">
              <Button
                href={`https://github.com/AnoXDD/${a.repo}`}
                target="_blank"
                tooltip="GitHub"
              >code</Button>
              {a.url ?
                <Button href={a.url}
                        tooltip="Link"
                        target="_blank">link</Button>
                : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}


export default class Repos extends Component {
  constructor() {
    super();

    this.state = {
      title      : "",
      description: "Hover or touch a tile to view the detail",
    };

    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  handleMouseOver(i) {
    let app = REPOS[i];

    if (app) {
      this.setState({
        title      : app.title,
        type       : app.type,
        description: app.description,
      });
    }
  }

  render() {
    return (
      <div className="content repos">
        <div className="display-area">
          <div className="detail flex-center">
            <div className="title">
              {this.state.title}
              <div className="type">{this.state.type}</div>
            </div>
            <div className="description">
              {this.state.description}
            </div>
          </div>
        </div>
        <List onMouseOver={this.handleMouseOver}/>
      </div>
    );
  }
}
