import React from "react";

export default class Tile extends React.PureComponent {
  render() {
    return <div className="tile content flex-center flex-wrap">
      {this.props.tiles.map(a =>
        <a key={a.title} href={a.url} target="_blank" rel="noopener noreferrer"
           className={`project ${this.props.className || ""}`}>
          <div className="img-wrapper">
            <img style={{
              backgroundImage   : `url(${a.img})`,
              backgroundPosition: "center",
              backgroundSize    : "cover",
            }} alt={""}/>
          </div>
          <div className="detail flex-center">
            <div className="title">{a.title}</div>
            <div className="description">{a.description}</div>
          </div>
        </a>
      )}
    </div>
  }
}