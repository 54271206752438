import React, {Component} from 'react';

import './css/app.css';

import profile from "./img/profile.png";
import Button from "./lib/Button";
import Sticky from "./components/Sticky";
import {APPS} from "./data";
import Repos from "./components/Repos";
import version from "./version";
import resume from "./data/resume.pdf";
import Tile from "./components/Tile";

const Projects = (
  <Tile tiles={APPS}/>
);

class App extends Component {
  constructor() {
    super();

    this.state = {
      y: 0,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
  }

  componentDidMount() {
    this.handleScroll();
  }

  handleScroll() {
    this.setState({
      y: window.pageYOffset,
    });
  }

  render() {
    return (
      <div className="App">
        <Sticky y={this.state.y}>
          <div className="section top flex-center">
            <div className="title">
              <div className="main">Runjie Guan</div>
              <div className="subtitle">
                UI/UX Engineer & Game Developer
              </div>
              <div className="btns">
                <Button href="mailto://guanrunjie@gmail.com">email</Button>
                <Button href="https://github.com/AnoXDD/"
                        text="Github">code</Button>
              </div>
            </div>
            <div className="img-wrapper">
              <img src={profile} alt="Profile"/>
            </div>
          </div>
        </Sticky>
        <div className="section">
          <div className="title">
            <div className="main">Apps</div>
          </div>
          {Projects}
        </div>
        <div className="section flex-center">
          <div className="title">
            <div className="main">Repos</div>
          </div>
          <Repos/>
        </div>
        <div className="section flex-center">
          <div className="title">
            <div className="main">About</div>
          </div>
          <div className="content about">
            <p>
              Currently working at Facebook, fortunate
              enough to have job and hobby overlapped.
            </p>
            <div className="btns">
              <Button text="resume" href={resume}
                      target="_blank">file_download</Button>
            </div>
          </div>
        </div>
        <div className="section flex-center footer">
          <p>© 2019 Runjie Guan. Designed by myself.</p>
          <p>Updated <span>{new Date(version).toLocaleDateString()}</span>
          </p>
        </div>
      </div>
    );
  }
}

export default App;
