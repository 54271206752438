import React, {Component} from "react";

export default class Sticky extends Component {
  constructor() {
    super();

    this.height = 0;
    this.top = 0;
  }

  //todo recalculate on resize
  componentWillUpdate(nextProps) {
    this.height = this.refs.comp.offsetHeight;
    this.top = this.refs.comp.getBoundingClientRect().top + nextProps.y;
  }

  render() {
    let style = null;

    if (this.height) {
      if (this.props.y >= this.top &&
        this.props.y + window.innerHeight >= this.top + this.height) {
        style = {
          position: "fixed",
          top     : -Math.max(0, this.height - window.innerHeight),
          left    : 0,
          width   : "100%",
        };
      } else {
        style = {
          position: "absolute",
          top     : this.top,
          left    : 0,
          width   : "100%",
        };
      }
    }

    return (
      <div ref="comp"
           className="sticky"
           style={this.height ? {height: this.height} : null}>
        <div style={style}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
