import trak from "./img/trak.anoxic.me.png";
import gamelets from "./img/gamelets.anoxic.me.png";
import hack from "./img/hack.png";
import runj from "./img/runj.png";
import unfold from "./img/unfold.png";
import huli_100 from "./img/huli/huli_100.gif";
import huli_101 from "./img/huli/huli_101.jpg";
import huli_102 from "./img/huli/huli_102.gif";
import huli_103 from "./img/huli/huli_103.gif";
import huli_200 from "./img/huli/huli_200.jpg";
import huli_201 from "./img/huli/huli_201.gif";
import huli_202 from "./img/huli/huli_202.jpg";
import huli_203 from "./img/huli/huli_203.jpg";
import huli_204 from "./img/huli/huli_204.gif";
import huli_205 from "./img/huli/huli_205.gif";
import huli_206 from "./img/huli/huli_206.jpg";
import huli_207 from "./img/huli/huli_207.jpg";
import huli_208 from "./img/huli/huli_208.jpg";
import huli_226 from "./img/huli/huli_226.jpg";
import huli_300 from "./img/huli/huli_300.jpg";
import huli_301 from "./img/huli/huli_301.jpg";
import huli_302 from "./img/huli/huli_302.gif";
import huli_303 from "./img/huli/huli_303.jpg";
import huli_304 from "./img/huli/huli_304.jpg";
import huli_305 from "./img/huli/huli_305.jpg";
import huli_306 from "./img/huli/huli_306.gif";
import huli_307 from "./img/huli/huli_307.jpg";
import huli_308 from "./img/huli/huli_308.jpg";
import huli_400 from "./img/huli/huli_400.gif";
import huli_401 from "./img/huli/huli_401.gif";
import huli_402 from "./img/huli/huli_402.jpg";
import huli_403 from "./img/huli/huli_403.gif";
import huli_404 from "./img/huli/huli_404.jpg";
import huli_405 from "./img/huli/huli_405.jpg";
import huli_406 from "./img/huli/huli_406.gif";
import huli_407 from "./img/huli/huli_407.jpg";
import huli_408 from "./img/huli/huli_408.jpg";
import huli_409 from "./img/huli/huli_409.jpg";
import huli_410 from "./img/huli/huli_410.gif";
import huli_411 from "./img/huli/huli_411.jpg";
import huli_412 from "./img/huli/huli_412.jpg";
import huli_413 from "./img/huli/huli_413.jpg";
import huli_414 from "./img/huli/huli_414.jpg";
import huli_415 from "./img/huli/huli_415.jpg";
import huli_416 from "./img/huli/huli_416.gif";
import huli_417 from "./img/huli/huli_417.gif";
import huli_418 from "./img/huli/huli_418.jpg";
import huli_421 from "./img/huli/huli_421.gif";
import huli_422 from "./img/huli/huli_422.jpg";
import huli_423 from "./img/huli/huli_423.jpg";
import huli_424 from "./img/huli/huli_424.jpg";
import huli_426 from "./img/huli/huli_426.jpg";
import huli_428 from "./img/huli/huli_428.gif";
import huli_429 from "./img/huli/huli_429.png";
import huli_431 from "./img/huli/huli_431.jpg";
import huli_451 from "./img/huli/huli_451.png";
import huli_500 from "./img/huli/huli_500.jpg";
import huli_501 from "./img/huli/huli_501.jpg";
import huli_502 from "./img/huli/huli_502.png";
import huli_503 from "./img/huli/huli_503.jpg";
import huli_504 from "./img/huli/huli_504.jpg";
import huli_505 from "./img/huli/huli_505.jpg";
import huli_506 from "./img/huli/huli_506.jpg";
import huli_507 from "./img/huli/huli_507.jpg";
import huli_508 from "./img/huli/huli_508.jpg";
import huli_510 from "./img/huli/huli_510.jpg";
import huli_511 from "./img/huli/huli_511.jpg";

export const APPS = [
  {
    title: "Trak",
    img: trak,
    description: "An online journal keeper",
    url: "https://trak.anoxic.me",
  }, {
    title: "Gamelets",
    img: gamelets,
    description: "A collection of homemade video games",
    url: "https://gamelets.anoxic.me",
  }, {
    title: "Hack",
    img: hack,
    description: "A console based puzzle game (Windows only)",
    url: "https://github.com/AnoXDD/Hack/releases",
  }, {
    title: "RunJ",
    img: runj,
    description: "A lightweight replacement for Win+R",
    url: "https://github.com/AnoXDD/RunJ/releases",
  }, {
    title: "Unfold",
    img: unfold,
    description: `A "lovely" multi-player labyrinth action game (Android/iOS)`,
    url: /android/i.test(navigator.userAgent ||
      navigator.vendor ||
      window.opera) ? "https://play.google.com/store/apps/details?id=edu.ucsd.gds&hl=en_GB" : "https://itunes.apple.com/us/app/unfold-game/id1003008772?mt=8",
  },
];

export const REPOS = [
  {
    title: "Trak",
    description: "An online journal keeper, with data secured by OneDrive",
    repo: "Journal-React",
    type: "ReactJS (SCSS)",
  },
  {
    title: "Gamelets",
    description: "A set of tiny (but challenging) video games. Mobile friendly",
    repo: "gamelets",
    type: "ReactJS (SCSS, Jest)",
  },
  {
    title: "sokoban-generator",
    description: "A configurable procedural level generator for the popular puzzle game Sokoban",
    repo: "sokoban-generator-javascript",
    url: "https://www.npmjs.com/package/sokoban-generator",
    type: "npm package",
  },
  {
    title: "flux-delay-dispatcher",
    description: "A Dispatcher for Flux that supports delayed event dispatch",
    repo: "flux-delay-dispatcher",
    url: "https://www.npmjs.com/package/flux-delay-dispatcher",
    type: "npm package (ReactJS, Flux, Jest)",
  },
  {
    title: "zyBoost",
    description: "(Educational purpose only) An extension to boost the efficiency of zyBook",
    repo: "zyBoost",
    url: "https://chrome.google.com/webstore/detail/zyboost/nefekikaklkbhmieegehdpbbkllcdnhl",
    type: "Chrome extension",
  },
  {
    title: "Bulber",
    description: "A companion app to push quick notes to Trak, available on Play Store",
    repo: "Journal-Bulber-Android",
    url: "https://play.google.com/store/apps/details?id=me.anoxic.bulber2",
    type: "Android app",
  },
  {
    title: "RunJ",
    description: "A lightweight replacement for Win+R",
    repo: "RunJ",
    type: "C#",
  },
  {
    title: "Hack",
    description: "A console based puzzle game with a story line (but kind of difficult)",
    repo: "Hack",
    type: "C++",
  },
];

export const HTML_100 = [
  {
    title: "100",
    img: huli_100,
    description: "Continue",
  },
  {
    title: "101",
    img: huli_101,
    description: "Switching Protocols",
  },
  {
    title: "102",
    img: huli_102,
    description: "Processing",
  },
  {
    title: "103",
    img: huli_103,
    description: "Early Hints",
  },
];

export const HTML_200 = [
  {
    title: "200",
    img: huli_200,
    description: "OK",
  },
  {
    title: "201",
    img: huli_201,
    description: "Created",
  },
  {
    title: "202",
    img: huli_202,
    description: "Accepted",
  },
  {
    title: "203",
    img: huli_203,
    description: "Non-Authoritative Information",
  },
  {
    title: "204",
    img: huli_204,
    description: "No Content",
  },
  {
    title: "205",
    img: huli_205,
    description: "Reset Content",
  },
  {
    title: "206",
    img: huli_206,
    description: "Partial Content",
  },
  // {
  //   title: "207",
  //   img: huli_207,
  //   description: "Multi-Status",
  // },
  // {
  //   title: "208",
  //   img: huli_208,
  //   description: "Already Reported",
  // },
  // {
  //   title: "226",
  //   img: huli_226,
  //   description: "IM Used",
  // },
];

export const HTML_300 = [
  {
    title: "300",
    img: huli_300,
    description: "Multiple Choices",
  },
  {
    title: "301",
    img: huli_301,
    description: "Moved Permanently",
  },
  {
    title: "302",
    img: huli_302,
    description: "Found",
  },
  {
    title: "303",
    img: huli_303,
    description: "See Other",
  },
  // {
  //   title: "304",
  //   img: huli_304,
  //   description: "Not Modified",
  // },
  {
    title: "305",
    img: huli_305,
    description: "Use Proxy",
  },
  {
    title: "306",
    img: huli_306,
    description: "Switch Proxy",
  },
  {
    title: "307",
    img: huli_307,
    description: "Temporary Redirect",
  },
  {
    title: "308",
    img: huli_308,
    description: "Permanent Redirect (Photo by Angier)",
  },
];

export const HTML_400 = [
  {
    title: "400",
    img: huli_400,
    description: "Bad Request",
  },
  {
    title: "401",
    img: huli_401,
    description: "Unauthorized",
  },
  // {
  //   title: "402",
  //   img: huli_402,
  //   description: "Payment Required",
  // },
  {
    title: "403",
    img: huli_403,
    description: "Forbidden",
  },
  {
    title: "404",
    img: huli_404,
    description: "Not Found",
  },
  {
    title: "405",
    img: huli_405,
    description: "Method Not Allowed",
  },
  {
    title: "406",
    img: huli_406,
    description: "Not Acceptable",
  },
  {
    title: "407",
    img: huli_407,
    description: "Proxy Authentication Required",
  },
  // {
  //   title: "408",
  //   img: huli_408,
  //   description: "Request Timeout",
  // },
  {
    title: "409",
    img: huli_409,
    description: "Conflict",
  },
  {
    title: "410",
    img: huli_410,
    description: "Gone",
  },
  {
    title: "411",
    img: huli_411,
    description: "Length Required",
  },
  // {
  //   title: "412",
  //   img: huli_412,
  //   description: "Precondition Failed",
  // },
  {
    title: "413",
    img: huli_413,
    description: "Payload Too Large",
  },
  {
    title: "414",
    img: huli_414,
    description: "URI Too Long",
  },
  {
    title: "415",
    img: huli_415,
    description: "Unsupported Media Type",
  },
  {
    title: "416",
    img: huli_416,
    description: "Range Not Satisfiable",
  },
  {
    title: "417",
    img: huli_417,
    description: "Expectation Failed",
  },
  // {
  //   title: "418",
  //   img: huli_418,
  //   description: "I'm a teapot",
  // },
  {
    title: "421",
    img: huli_421,
    description: "Misdirected Request",
  },
  {
    title: "422",
    img: huli_422,
    description: "Unprocessable Entity",
  },
  {
    title: "423",
    img: huli_423,
    description: "Locked",
  },
  {
    title: "424",
    img: huli_424,
    description: "Failed Dependency",
  },
  {
    title: "426",
    img: huli_426,
    description: "Upgrade Required",
  },
  {
    title: "428",
    img: huli_428,
    description: "Precondition Required",
  },
  {
    title: "429",
    img: huli_429,
    description: "Too Many Requests",
  },
  {
    title: "431",
    img: huli_431,
    description: "Request Header Fields Too Large",
  },
  {
    title: "451",
    img: huli_451,
    description: "Unavailable For Legal Reasons",
  },
];

export const HTML_500 = [
  {
    title: "500",
    img: huli_500,
    description: "Internal Server Error",
  },
  {
    title: "501",
    img: huli_501,
    description: "Not Implemented",
  },
  {
    title: "502",
    img: huli_502,
    description: "Bad Gateway",
  },
  {
    title: "503",
    img: huli_503,
    description: "Service Unavailable",
  },
  {
    title: "504",
    img: huli_504,
    description: "Gateway Timeout",
  },
  {
    title: "505",
    img: huli_505,
    description: "HTTP Version Not Supported",
  },
  {
    title: "506",
    img: huli_506,
    description: "Variant Also Negotiates",
  },
  {
    title: "507",
    img: huli_507,
    description: "Insufficient Storage",
  },
  {
    title: "508",
    img: huli_508,
    description: "Loop Detected",
  },
  {
    title: "510",
    img: huli_510,
    description: "Not Extended",
  },
  // {
  //   title: "511",
  //   img: huli_511,
  //   description: "Network Authentication Required",
  // },
];
