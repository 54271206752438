import React, {Component} from 'react';

import './css/app.css';

import Sticky from "./components/Sticky";
import {HTML_100, HTML_200, HTML_300, HTML_400, HTML_500} from "./data";
import Tile from "./components/Tile";
import profile from "./img/huli/profile.jpg";

class Huli extends Component {
  constructor() {
    super();

    this.state = {
      y: 0,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
  }

  componentDidMount() {
    this.handleScroll();
  }

  handleScroll() {
    this.setState({
      y: window.pageYOffset,
    });
  }

  render() {
    return (
      <div className="App">
        <Sticky y={this.state.y}>
          <div className="section top flex-center">
            <div className="title">
              <div className="main">Huli</div>
              <div className="subtitle">
                HTTP code, brought to you by a fluffy calico and her friends
              </div>
            </div>
            <div className="img-wrapper">
              <img src={profile} alt="Profile"/>
            </div>
          </div>
        </Sticky>
        <div className="section">
          <div className="title">
            <div className="main">100</div>
          </div>
          <Tile className="narrow" tiles={HTML_100}/>
        </div>
        <div className="section">
          <div className="title">
            <div className="main">200</div>
          </div>
          <Tile className="narrow" tiles={HTML_200}/>
        </div>
        <div className="section">
          <div className="title">
            <div className="main">300</div>
          </div>
          <Tile className="narrow" tiles={HTML_300}/>
        </div>
        <div className="section">
          <div className="title">
            <div className="main">400</div>
          </div>
          <Tile className="narrow" tiles={HTML_400}/>
        </div>
        <div className="section">
          <div className="title">
            <div className="main">500</div>
          </div>
          <Tile className="narrow" tiles={HTML_500}/>
        </div>
        <div className="section flex-center footer">
          <p>Inspired by <a href="https://http.cat/">https://http.cat/</a></p>
        </div>
      </div>
    );
  }
}

export default Huli;
