import React from 'react';
import ReactDOM from 'react-dom';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import App from './App';
import Huli from "./Huli";
import registerServiceWorker from './registerServiceWorker';

const routing = (
  <Router>
    <div>
      <Switch>
        <Route exact path="/" component={App}/>
        <Route path="/huli" component={Huli}/>
        <Route component={App}/>
      </Switch>
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById('root'));
registerServiceWorker();